import ApolloClient from "apollo-boost";

const client = new ApolloClient({
  uri: "https://api.are.na/graphql",
  headers: {
    "X-APP-TOKEN": "f0e391533bdc71ab64a3"
  }
});

export default client;
